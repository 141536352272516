<template>
  <section>
    <FormCif
      ref="formCifRef"
      @submitCifPersonIdentity="addCifPersonIdentity($event)"
      @submitCifEnterPriseIdentity="addCifEnterPriseIdentity($event)"
      :isExisting="false"
    />
  </section>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import FormCif from "../form";
import Mixin from "@/views/transactions/m-cif/mixins";

export default {
  name: "CreateCif",
  mixins: [Mixin],
  components: {
    FormCif,
    // eslint-disable-next-line vue/no-unused-components
    PulseLoader,
  },
  data() {
    return {
      mainId: null,
      submitLoading: false,
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: ''
    };
  },
  mounted() {
    this.validationDomain = window.location.hostname;
  },
  methods: {
    async addCifEnterPriseIdentity(payload) {
      const data = {
        appendAkte: payload.appendAkte,
        authorizedByUserCode: payload.authorizedByUser,
        authorizedByUserName: payload.authorizedByUserName,
        authorizedDate: payload.authorizedDate,
        birthDate: payload.birthDate,
        birthPlace: payload.birthPlace.toUpperCase(),
        businessSegmentCode: payload.businessSegmentCode,
        businessSegmentName: payload.businessSegmentName,
        cardTypeCode: payload.cardTypeCode,
        categoryId: payload.categoryId,
        cifAliasName: payload.cifAliasName.toUpperCase(),
        cifBeneficiaryFamilyName: payload.cifBeneficiaryFamilyName.toUpperCase(),
        cifBeneficiaryFamilyRelation: payload.cifBeneficiaryFamilyRelation,
        cifBeneficiaryIdcardNumber: payload.cifBeneficiaryIdcardNumber,
        cifBeneficiaryOwner: payload.cifBeneficiaryOwner.toUpperCase(),
        cifBeneficiaryRelation: payload.cifBeneficiaryRelation.toUpperCase(),
        cifCategoryName: payload.cifCategoryName,
        cifClassificationName: payload.cifClassificationName,
        cifCompanyRelatedName: payload.cifCompanyRelatedName,
        cifDomicileAddress: payload.cifDomicileAddress.toUpperCase(),
        cifEmail: payload.cifEmail,
        cifGroupOwnerName: payload.cifGroupOwnerName,
        cifId: payload.cifId,
        cifIdAddress: payload.cifIdAddress,
        cifIdCardNumber: payload.cifIdCardNumber,
        cifIdName: payload.cifIdName.toUpperCase(),
        cifIdcardExpiredDate: payload.cifIdCardExpiredDate,
        cifMobilePhoneNumber: payload.cifMobilePhoneNumber,
        cifNpwpNumber: payload.cifNpwpNumber,
        cifPhoneNumber: payload.cifPhoneNumber,
        cifPostalCode: payload.cifPostalCode,
        cifRealName: payload.cifRealName.toUpperCase(),
        cifSandiLocationName: payload.cifSandiLocationName,
        cifSidName: payload.cifSidName.toUpperCase(),
        cifStatusDomicileName: payload.cifStatusDomicileName,
        cityName: payload.cityName,
        classificationCode: payload.classificationCode,
        companyRelatedCode: payload.companyRelatedCode,
        createdByUserCode: payload.createdByUserCode,
        createdByUserName: payload.createdByUserName,
        createdDate: payload.createdDate,
        districtName: payload.districtName,
        groupOwnerCode: payload.groupOwnerCode,
        handleAccountOfficerId: payload.handleAccountOfficerId,
        idcardTypeShortname: payload.idcardTypeShortname,
        isDomicileIdcard: payload.isDomicileIdcard,
        isTaxable: payload.isTaxable,
        legalEntityId: payload.legalEntityId,
        legalEntityName: payload.legalEntityName,
        nationalityCode: payload.nationalityCode,
        nationalityName: payload.nationalityName,
        promoAccountOfficerId: payload.promoAccountOfficerId,
        provinceName: payload.provinceName,
        regionalCode: payload.regionalCode,
        sandiLocationCode: payload.sandiLocationCode,
        statusDomicileId: payload.statusDomicileId,
        updatedByUserCode: payload.updatedByUserCode,
        updatedByUserName: payload.updatedByUserName,
        updatedDate: payload.updatedDate,
        validationDomain: payload.validationDomain,
        villageId: payload.villageId,
        villageName: payload.villageName,
        npwpReason: payload.npwpReason.toUpperCase(),
      };
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        if (!window.navigator.onLine) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.submitLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "transaction",
            reqUrl: "cif",
            payload: data,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open({
                message: "Sukses !",
                type: "is-info",
                duration: 1000,
                position: "is-top",
                queue: false,
              });
              setTimeout(() => {
                this.$router.push(
                  `/cif/${this.encryptBASE64(
                    `${resp.data.data.cifId}`
                  )}/enterprise`
                );
              }, 500);
              return;
            }
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } catch (error) {
          setTimeout(() => {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } finally {
          setTimeout(() => {
            this.submitLoading = false;
          }, 1000);
        }
      } else {
        if (!window.navigator.onLine) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.submitLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "transaction",
            reqUrl: "cif",
            payload: data,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.$router.push(
                  `/cif/${this.encryptBASE64(
                    `${resp.data.data.cifId}`
                  )}/enterprise`
                );
              }, 500);
              return;
            }
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } catch (error) {
          setTimeout(() => {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } finally {
          setTimeout(() => {
            this.submitLoading = false;
          }, 1000);
        }
      }
    },
    async addCifPersonIdentity(payload) {
      const data = {
        cifIdName: payload.cifIdName.toUpperCase(),
        cifIdAddress: payload.cifIdAddress.toUpperCase(),
        authorizedByUserCode: payload.authorizedByUserCode,
        authorizedByUserName: payload.authorizedByUserName,
        authorizedDate: payload.authorizedDate,
        birthDate: payload.birthDate,
        birthPlace: payload.birthPlace.toUpperCase(),
        businessSegmentCode: payload.businessSegmentCode,
        businessSegmentName: payload.businessSegmentName,
        cardPublisherId: payload.cardPublisherId,
        cardTypeCode: payload.cardTypeCode,
        categoryId: payload.categoryId,
        cifAliasName: payload.cifAliasName.toUpperCase(),
        cifBeneficiaryFamilyName: payload.cifBeneficiaryFamilyName,
        cifBeneficiaryFamilyRelation: payload.cifBeneficiaryFamilyRelation,
        cifBeneficiaryIdcardNumber: payload.cifBeneficiaryIdcardNumber,
        cifBeneficiaryOwner: payload.cifBeneficiaryOwner.toUpperCase(),
        cifBeneficiaryRelation: payload.cifBeneficiaryRelation,
        cifCategoryName: payload.cifCategoryName,
        cifClassificationName: payload.cifClassificationName,
        cifCompanyRelatedName: payload.cifCompanyRelatedName,
        cifDependentNumber: payload.cifDependentNumber,
        cifDomicileAddress: payload.cifDomicileAddress.toUpperCase(),
        cifEmail: payload.cifEmail,
        cifFamilyRelationName: payload.cifFamilyRelationName,
        cifGroupOwnerName: payload.cifGroupOwnerName,
        cifIdCardNumber: payload.cifIdCardNumber,
        cifIdcardExpiredDate: payload.cifIdcardExpiredDate,
        cifMobilePhoneNumber: payload.cifMobilePhoneNumber,
        cifMotherName: payload.cifMotherName.toUpperCase(),
        cifNpwpNumber: payload.cifNpwpNumber,
        cifNumber: payload.cifNumber,
        cifParentNumber: payload.cifParentNumber,
        cifPhoneNumber: payload.cifPhoneNumber,
        cifPostalCode: payload.cifPostalCode,
        cifRealName: payload.cifRealName.toUpperCase(),
        cifSandiLocationName: payload.cifSandiLocationName,
        cifSidName: payload.cifSidName.toUpperCase(),
        cifStatusDomicileName: payload.cifStatusDomicileName,
        cityName: payload.cityName,
        classificationCode: payload.classificationCode,
        companyRelatedCode: payload.companyRelatedCode,
        createdByUserCode: payload.createdByUserCode,
        createdByUserName: payload.createdByUserName,
        createdDate: payload.createdDate,
        districtName: payload.districtName,
        educationId: payload.educationId,
        genderId: payload.genderId,
        genderName: payload.genderName,
        groupOwnerCode: payload.groupOwnerCode,
        handleAccountOfficerId: payload.handleAccountOfficerId,
        idcardPublisherShortname: payload.idcardPublisherShortname,
        idcardTypeShortname: payload.idcardTypeShortname,
        isDomicileIdcard: payload.isDomicileIdcard,
        isIdcardNeverExpired: payload.isIdcardNeverExpired,
        isTaxable: payload.isTaxable,
        maritalStatusId: payload.maritalStatusId,
        maritalStatusName: payload.maritalStatusName,
        nationalityCode: payload.nationalityCode,
        nationalityName: payload.nationalityName,
        npwpReason: payload.npwpReason.toUpperCase(),
        promoAccountOfficerId: payload.promoAccountOfficerId,
        provinceName: payload.provinceName,
        regionalCode: payload.regionalCode,
        religionId: payload.religionId,
        religionName: payload.religionName,
        sandiLocationCode: payload.sandiLocationCode,
        statusDegreeId: payload.statusDegreeId,
        statusDegreeName: payload.statusDegreeName,
        statusDomicileId: payload.statusDomicileId,
        updatedByUserCode: payload.updatedByUserCode,
        updatedByUserName: payload.updatedByUserName,
        updatedDate: payload.updatedDate,
        villageId: payload.villageId,
        villageName: payload.villageName,
      };
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        if (!window.navigator.onLine) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.submitLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "transaction",
            reqUrl: "cif",
            payload: data,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open({
                message: "Sukses !",
                type: "is-info",
                duration: 1000,
                position: "is-top",
                queue: false,
              });
              setTimeout(() => {
                this.$router.push(
                  `/cif/${this.encryptBASE64(`${resp.data.data.cifId}`)}/person`
                );
              }, 500);
              return;
            }
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } catch (error) {
          setTimeout(() => {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } finally {
          setTimeout(() => {
            this.submitLoading = false;
          }, 1000);
        }
      } else {
        if (!window.navigator.onLine) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.submitLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "transaction",
            reqUrl: "cif",
            payload: data,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.$router.push(
                  `/cif/${this.encryptBASE64(`${resp.data.data.cifId}`)}/person`
                );
              }, 500);
              return;
            }
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } catch (error) {
          setTimeout(() => {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } finally {
          setTimeout(() => {
            this.submitLoading = false;
          }, 1000);
        }
      }
    },
  },
};
</script>
